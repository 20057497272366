import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a5421c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "headCarousell",
  class: "carousell"
}
const _hoisted_2 = {
  key: 1,
  class: "carousell-dots"
}
const _hoisted_3 = {
  key: 2,
  class: "actionTargets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StartseiteHeadCarousellItem = _resolveComponent("StartseiteHeadCarousellItem")!
  const _component_StartseiteHeadCarousellGreeter = _resolveComponent("StartseiteHeadCarousellGreeter")!
  const _component_carousell_dot = _resolveComponent("carousell-dot")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.slideImportantData != null)
      ? (_openBlock(), _createBlock(_component_StartseiteHeadCarousellItem, {
          key: 0,
          "is-active": _ctx.activeIndex === -1,
          "slide-data": _ctx.slideImportantData
        }, null, 8, ["is-active", "slide-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_StartseiteHeadCarousellGreeter, {
      "is-active": _ctx.activeIndex === 0,
      "greeter-image": _ctx.greeterImage,
      onToTheDates: _ctx.linkDatesClicked
    }, null, 8, ["is-active", "greeter-image", "onToTheDates"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slideNormalData, (slide, index) => {
      return (_openBlock(), _createBlock(_component_StartseiteHeadCarousellItem, {
        key: slide.id,
        "is-active": _ctx.activeIndex === (index+1),
        "slide-data": slide
      }, null, 8, ["is-active", "slide-data"]))
    }), 128)),
    (_ctx.hasSlides)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.slideImportantData != null)
            ? (_openBlock(), _createBlock(_component_carousell_dot, {
                key: 0,
                "item-key": "-1",
                active: _ctx.activeIndex === -1,
                onDotClicked: _ctx.activateElement
              }, null, 8, ["active", "onDotClicked"]))
            : _createCommentVNode("", true),
          _createVNode(_component_carousell_dot, {
            "item-key": "0",
            active: _ctx.activeIndex === 0,
            onDotClicked: _ctx.activateElement
          }, null, 8, ["active", "onDotClicked"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slideNormalData, (slide, index) => {
            return (_openBlock(), _createBlock(_component_carousell_dot, {
              key: slide.id,
              "item-key": index + 1,
              active: _ctx.activeIndex === (index + 1),
              onDotClicked: _ctx.activateElement
            }, null, 8, ["item-key", "active", "onDotClicked"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.slideshowRunning)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "actionBack",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
          }),
          _createElementVNode("div", {
            class: "actionNext",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goNext && _ctx.goNext(...args)))
          })
        ]))
      : _createCommentVNode("", true)
  ], 512))
}