
import { defineComponent, PropType } from 'vue';
import StartseiteHeadCarousellGreeter from '@/components/Startseite/StartseiteHeadCarousellGreeter.vue';
import StartseiteHeadCarousellItem from '@/components/Startseite/StartseiteHeadCarousellItem.vue';
import { TimeProgressEvents } from '@/other/EventEmitter';
import mitt from 'mitt';
import CarousellDot from '@/components/Allgemein/CarousellDot.vue';
import { nanoid } from 'nanoid';
import { Swipe } from '@/other/swipelistener-ts';
import { DirectusImageObject } from '@/api/DirectusDTOs';
import { loadStartseiteSlideData, loadStartseiteSlidesData, StartSlidesData } from '@/api/ApiStartseite';
import { BaseData } from '@/data_models/BaseData';
import { MainSlidesData } from '@/api/ApiMainSlides';

export type imgLoadedData = 'important' | 'greeter' | number;

export default defineComponent({
  name: 'StartseiteHeadCarousell',
  components: { CarousellDot, StartseiteHeadCarousellItem, StartseiteHeadCarousellGreeter },
  emits: ['to-the-dates', 'preload-data-completed', 'preload-img-completed', 'preload-failed'],
  props: {
    greeterImage: {
      type: Object as PropType<DirectusImageObject>,
      required: true
    },
    importantSlide: {
      type: Number,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      slideshowRunning: true,
      activeIndex: this.importantSlide != null ? -1 : 0,
      itemDuration: 4000,
      eventEmitter: mitt<TimeProgressEvents>(),
      planedNext: '',
      imagesLoaded: { important: false, greeter: false, normal1: false }
    };
  },
  computed: {
    hasSlides (): boolean {
      if (this.slideImportantData != null) {
        return true;
      }

      if (this.slideNormalData.length > 0) {
        return true;
      }

      return false;
    },
    itemCount (): number {
      let count = 1;
      if (this.slideImportantData != null) {
        count++;
      }

      count += this.slideNormalData.length;
      return count;
    },
    slideImportantData (): MainSlidesData | null {
      if (this.importantSlide == null || isNaN(this.importantSlide)) {
        return null;
      }

      return BaseData.mainSlides?.find(x => x.id === this.importantSlide) ?? null;
    },
    slideNormalData (): Array<MainSlidesData> {
      return BaseData.mainSlides?.filter(x => x.id !== this.importantSlide).slice(0, 5) ?? [];
    }
  },
  mounted () {
    if (this.slideImportantData === null) {
      this.activeIndex = 0;
    }

    this.activateSwipe();
    this.startSlideshow();
  },
  methods: {
    startSlideshow (): void {
      // If there are no slides do not start anything
      if (!this.hasSlides) {
        return;
      }

      this.planNext();
      setTimeout(() => this.eventEmitter.emit('start'), 20);
    },
    activateSwipe (): void {
      const element = this.$refs.headCarousell as HTMLElement;
      const resistance = Math.floor(element.clientWidth / 8);

      const options = {
        moveCallbacks: false,
        resistance: resistance
      };
      const swipe = new Swipe(element, options);
      swipe.init();
      swipe.on('swipeleft', this.goBack);
      swipe.on('swiperight', this.goNext);
    },
    linkDatesClicked () {
      this.$emit('to-the-dates');
    },
    activeNext (nId: string): void {
      if (nId !== this.planedNext) {
        return;
      }

      this.goNext();
    },
    activateElement (e: string) {
      const i = Number(e);

      if (isNaN(i) || i === this.activeIndex) {
        return;
      }

      this.activeIndex = i;
      this.eventEmitter.emit('restart');

      this.planNext();
    },
    goBack (): void {
      this.planNext();
      this.eventEmitter.emit('restart');

      // Wenn innerhalb der "normalen" Slides, dann einfach zu der Slide davor wechseln
      if (this.activeIndex > 0) {
        this.activeIndex--;
        return;
      }

      // Wenn auf der "Important"-Slide, dann zur letzten normalen Slide wechseln
      if (this.activeIndex === -1) {
        this.activeIndex = this.slideNormalData.length;
        return;
      }

      // Jetzt kann der aktuelle Stand nur noch die "Welcome/Greeter"-Slide sein.
      // Entweder auf die "Important" (falls vorhanden) oder auf die letzte normale wechseln
      if (this.slideImportantData != null) {
        this.activeIndex = -1;
        return;
      }

      this.activeIndex = this.itemCount - 1;
    },
    goNext (): void {
      this.planNext();
      this.eventEmitter.emit('restart');

      const maxSlideIndex = this.slideNormalData.length;

      if (this.activeIndex >= maxSlideIndex) {
        if (this.slideImportantData !== null) {
          this.activeIndex = -1;
          return;
        }

        this.activeIndex = 0;
        return;
      }

      this.activeIndex++;
    },
    planNext (): void {
      const id = nanoid(10);
      this.planedNext = id;
      setTimeout(() => this.activeNext(id), this.itemDuration);
    }
  }
});
