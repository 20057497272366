import { StartData } from '@/api/ApiStartseite';
import { reactive } from 'vue';
import { PageBaseData } from '@/api/ApiBaseDate';
import { MainSlidesData } from '@/api/ApiMainSlides';
import { ImprintData } from '@/api/ApiImprint';
import { DatenschutzData } from '@/api/ApiDatenschutz';

export const BaseData = reactive({
  pageBaseData: null as null | PageBaseData,
  startData: null as null | StartData,
  mainSlides: null as null | Array<MainSlidesData>,
  impressum: null as null | ImprintData,
  datenschutz: null as null | DatenschutzData
});
