/* eslint-disable camelcase */
import { client } from '@/api/Directus';

export interface ImprintData {
  'id': number,
  'status': string,
  'introduction': string | null,
  'imprint_contact': string
}

export async function loadImprintData (): Promise<ImprintData> {
  const response = await client.getItem<ImprintData>(
    'impressum',
    1
  );

  return response.data;
}
