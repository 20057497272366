/* eslint-disable camelcase */
import { client } from '@/api/Directus';

export interface DatenschutzData {
  'id': number,
  'status': string,
  'privacy_statement': string,
}

export async function loadPrivacyStatementData (): Promise<DatenschutzData> {
  const response = await client.getItem<DatenschutzData>(
    'privacy_statement',
    1
  );

  return response.data;
}
